<template>
  <div class="mainpage">
    <div class="logintitle">
      <img src="@/assets/nhlogin/nhlogo.png" alt />
    </div>
    <md-field>
      <p class="sendmsg">
        我们已经发送一条短信至
        <span>{{phone}}</span>
      </p>
      <p class="sendmsg">请输入短信中的验证码</p>
      <div class="yzm">
        <md-input-item type="text" maxlength="4" v-model="yzm" placeholder="短信验证码"></md-input-item>
        <div
          class="yambtn"
          :style="yzmstyle"
          @click="getyzm"
          id="getyzm1"
        >{{djsbt?('('+ second+')'+yzmbtext):yzmbtext}}</div>
      </div>
      <md-button :loading="btnloading" :inactive="btnloading" @click="register">{{bttext}}</md-button>
      <div class="xyword">
        <p class="frontpic">
          <img src="@/assets/nh/uncheck.png" alt v-if="!isxz" @click="isxz = !isxz" />
          <img src="@/assets/nh/check.png" alt v-else @click="isxz = !isxz" />
          <span @click="isxz = !isxz" class="readend">我已阅读并接受</span>
          <!-- <span
            @click="toshow('manment')"
          >《汇立天下会员管理制度》</span>
          <span
            @click="toshow('vipserve')"
          >《汇立天下会员服务协议》</span> -->
          <span
            @click="toshow('zcxy')"
          >《汇立天下注册协议》</span>
          <span
            class="zuihou"
            @click="toshow('statement')"
          >《汇立天下隐私服务协议》</span>
        </p>
      </div>
    </md-field>
    <md-landscape v-model="showFullScreen" full-screen class="landscape">
      <div class="pdf">
        <div>
          <pdf
            v-for="i in numPages"
            :key="i"
            :page="i"
            :src="filepath"
            style="width: 100%; height: auto;"
            @num-pages="pageCount=$event"
          ></pdf>
        </div>
      </div>
    </md-landscape>
    <div class="closepdf" v-if="showFullScreen" @click="showFullScreen = !showFullScreen">关闭</div>
  </div>
</template>
<script>
import { Toast } from 'mand-mobile'
import {
  getregisteryzm,
  register,
  getInit,
  getmsgloginyzm,
  yzmlogin,
  login
} from '@/api/basic'
import { setStorage, getStorage } from '@/lib/util'
import pdf from 'vue-pdf'
const REGISTER_YZM = Symbol('REGISTER_YZM')
export default {
  components: {
    pdf
  },
  data () {
    return {
      showFullScreen: false,
      filepath: '',
      pageCount: 0,
      numPages: 0,
      btnloading: false,
      isxz: false,
      yzm: '',
      yzmstyle: '',
      djsbt: false,
      second: 59,
      yzmbtext: '获取验证码',
      timer: null,
      password: '',
      page: '',
      bttext: '注册',
      phone: '',
      httptype: ''
    }
  },
  watch: {
    '$route' (to, from) {
      this.phone = this.$route.query.phone
    }
  },
  created () {
    this.phone = this.$route.query.phone
    this.page = this.$route.query.page
    this.bttext = this.page === 'yzm' ? '登录' : '注册'
    this.toloading()
    this.httptype = window.location.hostname.split('.')[0]
  },
  mounted () {
    this.yzm = window[REGISTER_YZM] || ''
  },
  methods: {
    register () {
      if (!this.isxz) {
        Toast.info('请勾选同意相关协议')
      } else {
        if (this.yzm === '') {
          Toast.info('请填写正确验证码')
        } else {
          this.form_submit()
        }
      }
    },
    getyzm () {
      const PHONE = { phone: this.phone }
      const GET_YZM = {
        register: () => getregisteryzm(PHONE),
        yzm: () => getmsgloginyzm(PHONE)
      }
      GET_YZM[this.page]().then(() => {
        this.toloading()
      })
    },
    toloading () {
      this.djsbt = true
      this.yzmbtext = '秒后重试'
      this.yzmstyle =
        'background-color:#C1BEC1;color:white;pointer-events:none'
      this.timer = setInterval(() => {
        this.second -= 1
        if (this.second === 0) {
          this.changeloginmethod()
        }
      }, 1000)
    },
    /*
    :inactive="((page === 'login' && (phoneorempno == '' || password == '')) ? true : false)||(((page === 'yzm' || page === 'register') && (phone == '' || yzm == '' || !isxz)) ? true : false)"
    */
    changeloginmethod () {
      this.djsbt = false
      clearInterval(this.timer)
      this.yzmbtext = '获取验证码'
      this.second = 59
      this.password = ''
      this.yzm = ''
      this.yzmstyle =
        'background-color:#F4F1F2;color:#fb6822;pointer-events:auto'
    },
    form_submit () {
      const form_list = { phone: this.phone, code: this.yzm }
      const LOGIN_AND_REGISTER = {
        register: () => register(form_list),
        yzm: () => yzmlogin(form_list)
      }
      this.btnloading = true
      LOGIN_AND_REGISTER[this.page]()
        .then(res => {
          this.btnloading = false
          getInit().then(res => {
            let { ketel, kfqrcode } = res.data.data
            setStorage('u_s', res.data.data.user)
            setStorage('m_l', res.data.data.menulist)
            setStorage('ossurl', res.data.data.ossurl)
            setStorage('comname', res.data.data.comname)
            setStorage('banner', res.data.data.banner)
            setStorage('bar', res.data.data.bar)
            setStorage('kfInfo', { ketel, kfqrcode })
            setStorage('qrcode', res.data.data.qrcode)
            setStorage('website', res.data.data.website)
            setStorage('flag', res.data.data.user.promotionprice)
            this.httptype == 'qd'
              ? this.$router.push({ path: '/main' })
              : this.$router.push({ path: '/' })
          })
        })
        .finally(() => {
          this.btnloading = false
        })
    },
    toshow (file) {
      // this.numPages = 0;
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.filepath = pdf.createLoadingTask(file);
      //     this.filepath.then(pdf => {
      //       console.log(pdf);
      //       this.numPages = pdf.numPages;
      //     });
      //     this.showFullScreen = true;
      //   }, 500);
      // });
      window[REGISTER_YZM] = this.phoneorempno
      this.$router.push(`/${file}`)
    }
  }
}
</script>
<style lang="stylus" scoped>
.xyword {
  display: flex;
  justify-content: center;
  margin-top: 100px;

  .frontpic {

    img {
      width: 36px;
      height: 36px;
    }

    span {
      font-size: 34px;
      color: #108EE9;
      font-family: PingFang SC;
      margin: 0 2px;
    }
    .readend {
    font-size: 34px;
    font-family: PingFang SC;
    color: rgba(56, 56, 56, 1);
  }
  }
}

.logintitle {
  height: 18vh;
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
  img {
    width: 36vw;
    height: 11vw;
    margin-top: 100px;
  }
  span {
    padding: 100px 0 0;
    font-family: PingFang SC;
    color: rgba(0, 0, 0, 1);
    font-size: 54px;
    font-weight: 500;
  }
}

.sendmsg {
  font-family: PingFang SC;
  color: rgba(56,56,56,1);
  line-height: 65px;
  padding: 10px;
  font-size: 38px;
}

.sendmsg:nth-child(2) {
  padding-bottom: 50px;
}

.sendmsg:nth-child(1) {
  span {
    color: #4EA6E6;
  }
}

/deep/ .md-field-item-content::before {
  width: 0;
}

/deep/ .md-button {
  margin: 80px 0 0;
  background-color: color-primary;
  height: 120px;
  border-radius: 80px;
  color: white;
}

/deep/ .md-input-item-input {
  background: #f2f2f2;
  font-size: 40px;
  padding: 0 40px;
  border-radius: 12px;
}

.mainpage {
  width: 100vw;
  height: 100vh;
  background: white;
}

/* 验证码 */
/deep/ .yzm {
  display: flex;
  align-items: center;

  .md-field-item {
    position: relative;
    width: 75%;

    .md-input-item-input {
      border-radius: 12px 0 0 12px;
    }
  }

  .yambtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    width: 25%;
    height: 1rem;
    background-color: pink;
    border-radius: 0 12px 12px 0;
    background: #F4F1F2;
    color: color-primary;
    border-left: 1px solid lightgray;
  }
}

.closepdf {
  position: fixed !important;
  /* right: 0.25rem; */
  /* top: 0.25rem; */
  bottom: 0px !important;
  margin: 0 auto !important;
  width: 100vw !important;
  margin: auto !important;
  /* margin-left: 10vw; */
  height: 110px !important;
  background-color: color-primary !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white;
  z-index: 9999;
}

/deep/ .md-icon-clear:before, .md-icon-fail:before {
  content: '';
}
</style>
